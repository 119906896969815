import React from "react";
import * as styles from './imprint.module.css';

export default class ImprintContent extends React.Component {

    render() {
        return (
            <div className={"container " + styles.padding}>
                <h1>Impressum</h1>
                <p>
                    <address>
                        Erwin Kronschl&auml;ger - Kronschl&auml;ger Reisen<br/>
                        Kapping 4<br/>
                        4723 Natternbach<br/>
                        &Ouml;sterreich<br/>
                        Email: info@kronschlaeger-reisen.at<br/>
                        Telefon: 0664 5030634
                    </address>
                </p>
                <p>
                    Unternehmensgegenstand: Taxi- & Mietwagen-Gewerbe
                </p>
                <p>Umsatzsteuer-Identifikationsnummer: ATU62771414</p>
                <p>
                    Mitglied der WK&Ouml;, WKO&Ouml;
                    {/*Landesinnung Tischler, Bundesinnung Tischler*/}
                </p>
                <p>
                    Berufsrecht: Gewerbeordnung: <a href={"https://www.ris.bka.gv.at"}
                                                    target={"_blank"} rel={"noopener noreferrer"}>www.ris.bka.gv.at</a>
                </p>
                <p>Bezirkshauptmannschaft Grieskirchen</p>
                <p>
                    Verbraucher haben die M&ouml;glichkeit, Beschwerden an die OnlineStreitbeilegungsplattform der EU zu
                    richten: <a href={"http://ec.europa.eu/odr"} target={"_blank"}
                                rel={"noopener noreferrer"}>http://ec.europa.eu/odr</a>.<br/>
                    Sie können allfällige Beschwerde auch an die oben angegebene E-Mail-Adresse richten.
                </p>
                <h2>
                    Rechtshinweis
                </h2>
                <p>
                    Erwin Kronschläger hat diese Website erstellt und ist bemüht, die darin
                    enthaltenen Informationen sorgfältig zu prüfen und zu aktualisieren. Dennoch kann es vorkommen, dass
                    sich Daten inzwischen verändert und ihre Gültigkeit verloren haben. Erwin Kronschläger übernimmt
                    daher keine Haftung oder Garantie für die Aktualität, Richtigkeit und Vollständigkeit
                    der veröffentlichten Informationen.
                </p>
            </div>
        );
    }
}